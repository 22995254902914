import Axios from 'axios';

const timeout = 30000;

export const BASE_URL = 'https://nadezhnypartner-uk.ru/'


export const getTranparency = async () => {
    const axiosInstance = Axios.create({
      timeout,
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
      },
    });
    const link = `${BASE_URL}api/get_total.php`;
    return (await axiosInstance.get(link)).data;
};

export const getDocsList = async () => {
  const axiosInstance = Axios.create({
    timeout,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  });
  const link = `${BASE_URL}api/docs_list.php`;
  return (await axiosInstance.get(link)).data;
};

export const updateSiteInfo = async (body, pas) => {
  const axiosInstance = Axios.create({
    timeout,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  });
  const link = `${BASE_URL}api/update.php`;
  return (await axiosInstance.post(link, {body, pas}))?.data;
};

export const updateDocList = async (body, pas) => {
  const axiosInstance = Axios.create({
    timeout,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  });
  const link = `${BASE_URL}api/updateDocs.php`;
  return (await axiosInstance.post(link, {body, pas}))?.data;
};

export const updateEpif = async (body, pas) => {
  const axiosInstance = Axios.create({
    timeout,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  });
  const link = `${BASE_URL}api/update_epif.php`;
  return (await axiosInstance.post(link, {body, pas}))?.data;
};

export const uploadFilres = async (file, name, pas) => {
  const axiosInstance = Axios.create({
    timeout,
    headers: {
      "Content-Type": "multipart/form-data",
      accept: 'application/json',
    },
  });
  const link = `${BASE_URL}api/upload.php`;
  const formData = new FormData();

  formData.append('file', file, name);
  formData.append(`pas`, pas);
 
  return (await axiosInstance.post(link, formData))?.data;
};

export default getTranparency