import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import getTranparency from '../utils/api';
import SectionHeader from '../components/sections/partials/SectionHeader';
import { SectionTilesProps } from '../utils/SectionProps';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const InfoEpif = ({
  className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...rest
}) => {
    const [docs, setDocs] = useState(null)
  
    useEffect (() => {
      getTranparency()
        .then((res) => {
          setDocs(res)
        })
        .catch()
    },[])


    if(!docs) {
      return <></>
    }

    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      'illustration-section-05'
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: docs.epif.title,
      paragraph: ''
    };

    // reveal-scale-up
    return (
      <section
        {...rest}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div style={{ fontSize: 16, lineHeight: '19px', textAlign: 'center'}} className='illustration-section-01'>
              {
                docs.epif.description.map(el => <p>{el}</p>)
              }
              <p>
                <strong>
                    107031, г. Москва, Дмитровский пер., д. 9. Адрес в сети Интернет: <a href="https://nadezhnypartner-uk.ru/">https://nadezhnypartner-uk.ru/</a>
                </strong>
              </p>
              <p style={{textAlign: 'center'}}>
                Под управлением компании находятся следующие закрытые паевые инвестиционные фонды:
              </p>
            </div>
            <div className='infoList testimonial section illustration-section-09'>
              {docs?.epif?.list?.length ? docs?.epif?.list?.map((el) => (
                <div className='infoList_item'>
                  <span className='infoList_title'>{el.title}</span>
                  <span className='infoList_desc'>{el.description}</span>
                </div>
              )) : <div style={{textAlign: 'center', fontStyle: 'italic' }}>Пока ни одного фонда не добавлено</div>}
            </div>
          </div>
        </div>
      </section>
    );
}

InfoEpif.propTypes = propTypes;
InfoEpif.defaultProps = defaultProps;
    
export default withRouter(InfoEpif);