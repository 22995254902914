import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutAlternative from './layouts/LayoutAlternative';

// Views 
import Home from './views/Home';
import { Redirect, Route } from 'react-router-dom/cjs/react-router-dom.min';
import Documents from './views/Documents';
import Maintainer from './views/Maintainer';
import Login from './views/Login';
import './App.scss'
import InfoEpif from './views/InfoEpif';

class App extends React.Component {

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutAlternative} />
            <AppRoute exact path="/documents/:id" component={Documents} layout={LayoutAlternative} />
            <AppRoute exact path="/documents" component={Documents} layout={LayoutAlternative} />
            <AppRoute exact path="/info-epif" component={InfoEpif} layout={LayoutAlternative} />
            <AppRoute exact path="/login" component={Login} layout={LayoutAlternative} />
            <AppRoute exact path="/maintainer" component={Maintainer} layout={LayoutAlternative} />
            <Route path="*"><Redirect to="/" /></Route>
          </Switch>
        )} />
    );
  }
}

export default withRouter(props => <App {...props} />);